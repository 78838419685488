import React from 'react'
import RevolutionAI1 from '../../assets/img/revolutionBg1.svg'
import RevolutionAI2 from '../../assets/img/revolutionBg2.svg'
import RevolutionAI3 from '../../assets/img/revolution.jpg'
import './Revolution.modules.css'

const Revolution = () => {
  return (
    <div className='border revolution__container' style={{backgroundImage: `url(${RevolutionAI3})`}}>
        <div className="overlay"></div>
        <div className="row">
            <div className="col-md-3">
                <img src={RevolutionAI1} className='w-100 position-relative' style={{zIndex: '2', opacity: '0.7'}} alt="" />
            </div>
            <div className="col-md-6 text-content">
                <h1>The Revolution of AI Today with Big Data </h1>
                <h6>The integration of Artificial Intelligence (AI) and big data is revolutionizing industries by empowering machines to learn from vast datasets, driving innovation, operational efficiency, and unlocking new frontiers in various sectors, heralding a more intelligent and data-driven future.</h6>
            </div>
            <div className="col-md-3">
                <img src={RevolutionAI2} className='w-100 position-relative' style={{zIndex: '2', opacity: '0.7'}} alt="" />
            </div>
        </div>
      
    </div>
  )
}

export default Revolution
