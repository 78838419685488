import React, { useState, useEffect } from 'react';
import HeroImg from '../../assets/img/heroImg.jpg';
import './Hero.modules.css';
import BigData from '../BigData/bigData_data';

const Hero = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % BigData.length);
    }, 2500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className='hero_section' style={{backgroundImage: `url(${HeroImg})`}}>
      <div className="overlay"></div>
      <div className="title-container">
        <h2 style={{fontSize: '3rem'}} className='w-100' > We Are A: </h2>
        <div className='d-flex flex-row gap-2 w-100' >
          {BigData.map((data, index) => (
            <h1
              key={index}
              className={`title ${index === currentIndex ? 'active' : ''}`}
              style={{ display: index === currentIndex ? 'block' : 'none', fontSize: '4rem' }}
            >
            {data.title} Company
            </h1>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Hero;
