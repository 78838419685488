import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import GraphData from './graphData'
import './Graphs.modules.css'
import { FaChevronRight } from "react-icons/fa"
import DemoModal from '../DemoModal/DemoModal'

const Graphs = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };
    
    const closeModal = () => {
        setIsModalOpen(false);
    };

  return (
    <div className='py-5'>
        <div className="container graph_section">
            {/* <h1 className='text-center'>Graphs & AI Modules</h1> */}
            {GraphData.map((graph, index) => (
                <div className={`row my-5 ${index % 2 !== 0 ? 'flex-row-reverse' : ''}`} key={index}>
                        <div key={index} className="col-md-6 d-flex flex-column align-items-start justify-content-center gap-3">
                            <div className="main_section">
                                <h5 className='text-primary'>{graph.header}</h5>
                                <h2 className='mb-5'>{graph.title}</h2>
                                <p>{graph.desc}</p>
                            </div>
                            <Link className='p-0 m-0 text-primary d-flex flex-row align-items-center gap-2' onClick={openModal}>{graph.link}<FaChevronRight /></Link>
                        </div>
                    <div className="col-md-6">
                        <div className='graph_content-bg' style={{backgroundImage: `url(${graph.img})`}} ></div>
                    </div>
                    {index % 2 === 0 &&  <hr className='mt-5' />}
                   
                </div>
            ))}
        </div>
        {isModalOpen && <DemoModal modalIsOpen={isModalOpen} closeModal={closeModal} />}
    </div>
  )
}

export default Graphs
