import React from 'react'
import './ContactUs.modules.css'
import MapSection from '../../assets/img/mapSection.png'
import Office from '../../assets/img/company.jpg'
import { Link } from 'react-router-dom'

const ContactUs = () => {
  return (
    <>
        <div className="row">
            <div className="col-md-6">
                <img src={Office} className='w-100' alt="" />
            </div>
            <div className="col-md-6">
                <div className="location">
                    <h2>57 Spyrou Kyprianou Avenue</h2>
                    <h5 className='w-75'> Bybloserve Business Centre 5th floor - Office 504 6051, Larnaca Cyprus</h5>
                </div>
                <div className="call_us my-3">
                    <p className='mb-0 pb-0'>Call us:</p>
                    <h5>+357 24 812171</h5>
                </div>
                <div className="row my-4">
                    <div className="col-md-6">
                        <div className="map_container">
                            <Link to = "https://maps.app.goo.gl/Q1kGasxAodETkBdC8" target="_blank">
                                <img src={MapSection} className='w-100' alt="" />
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-6 px-4">
                        <div className="d-flex flex-column align-items-center title_hours">
                            <span className='w-100 border p-0' style={{borderStye: 'dashed'}}> </span>
                            <h3 className='text-primary my-3'>HOURS</h3>
                            <span className='w-100 border p-0' style={{borderStye: 'dashed'}}> </span>
                        </div>
                        <div className='my-2 d-flex flex-row align-items-center justify-content-between'>
                            <span>Mon-Fri:</span>
                            <span className='fw-bold'>8:30AM - 5PM</span>
                        </div>
                        <div className='my-2 d-flex flex-row align-items-center justify-content-between'>
                            <span>Sat-Sun:</span>
                            <span className='fw-bold'>Closed</span>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        
    </>
  )
}

export default ContactUs
