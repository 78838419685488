import React from 'react'
import Menu from './components/Menu/Menu'
import Hero from './components/Hero/Hero'
import BigData from './components/BigData/BigData'
import Graphs from './components/Graphs/Graphs'
import Request from './components/Request/Request'
import Revolution from './components/Revolution/Revolution'
import ContactUs from './components/ContactUs/ContactUs'
import Footer from './components/Footer/Footer'


const Home = () => {
  return (
    <div>
      <Menu />
      <Hero />
      <BigData />
      <Graphs />
      <Revolution />
      <Request />
      <ContactUs />
      <Footer />
    </div>
  )
}

export default Home
