import React from 'react';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '10',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    width: '400px',
    backgroundColor: '#f9f9f9',
  },
};

Modal.setAppElement('#root');

const DemoModal = ({ modalIsOpen, closeModal }) => {
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Request A Demo</h2>
        <button 
          onClick={closeModal} 
          style={{
            background: 'transparent',
            border: 'none',
            fontSize: '30px',
            position: 'absolute',
            top: '0',
            right: '10px',
            cursor: 'pointer'
          }}
        >
          &times;
        </button>

        <form style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <label htmlFor="reName" style={{ fontWeight: 'bold' }}>Full Name</label>
          <input 
            type="text" 
            id="reName" 
            style={{
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '5px'
            }}
          />
          <label htmlFor="reEmail" style={{ fontWeight: 'bold' }}>Business Email</label>
          <input 
            type="email" 
            id="reEmail" 
            style={{
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '5px'
            }}
          />
          <label htmlFor="rePhone" style={{ fontWeight: 'bold' }}>Phone Number</label>
          <input 
            type="text" 
            id="rePhone" 
            style={{
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '5px'
            }}
          />
          <button 
            type="submit" 
            style={{
              padding: '10px',
              border: 'none',
              borderRadius: '5px',
              backgroundColor: '#007BFF',
              color: '#fff',
              fontSize: '16px',
              cursor: 'pointer',
              marginTop: '20px'
            }}
          >
            Submit
          </button>
        </form>
      </Modal>
    </div>
  );
};

export default DemoModal;
