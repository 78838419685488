const data = [
  { 
    title: "Data Processing", 
    description: "We process raw data to make it usable. This involves converting data into a format that can be easily accessed, analyzed, and utilized by various data analysis tools. Our data processing services ensure that you get clean, reliable data for your business needs." 
  },
  { 
    title: "Cleansing", 
    description: "We clean data to ensure accuracy and reliability. This involves detecting and correcting (or removing) corrupt or inaccurate records from a dataset. Data cleansing improves data quality and ensures that your data-driven decisions are based on accurate information." 
  },
  { 
    title: "Structuring", 
    description: "We structure data for efficient analysis. This process includes organizing data according to predefined formats and structures, making it easier to query and analyze. Proper data structuring enhances the efficiency and effectiveness of data analysis processes." 
  },
  { 
    title: "Trade Area Analysis", 
    description: "We analyze trade areas for business insights. Trade area analysis involves studying the geographic area surrounding your business to understand customer demographics, competitor locations, and market potential. This analysis helps in making informed decisions about marketing and expansion." 
  },
  { 
    title: "Footfall Analysis", 
    description: "We analyze footfall to understand customer behavior. Footfall analysis measures the number of people entering a location, helping businesses understand traffic patterns, peak times, and customer preferences. This information is crucial for optimizing store layouts, staffing, and marketing strategies." 
  },
  { 
    title: "Dwell Analysis", 
    description: "We analyze dwell time to gauge engagement. Dwell analysis tracks how long customers stay at a particular location, providing insights into customer engagement and interest levels. This data is valuable for improving customer experience and increasing conversion rates." 
  },
  { 
    title: "Feasibility Analysis", 
    description: "We assess the feasibility of projects. Feasibility analysis evaluates the practicality and potential success of a proposed project. This includes analyzing financial, operational, and market factors to determine whether the project is viable and likely to achieve desired outcomes." 
  },
  { 
    title: "Promotional Analysis", 
    description: "We analyze promotional efforts to measure success. Promotional analysis evaluates the effectiveness of marketing campaigns, helping businesses understand which promotions drive the most engagement and sales. This analysis guides future marketing strategies and budget allocation." 
  },
  { 
    title: "Competitive Analysis", 
    description: "We analyze competitors to identify opportunities. Competitive analysis involves assessing the strengths and weaknesses of competitors in your market. This helps businesses understand their competitive position, identify market trends, and uncover opportunities for differentiation and growth." 
  }
];

  export default data;