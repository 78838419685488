import React, { useState } from 'react'
import { FaChevronRight } from 'react-icons/fa'
import './Request.modules.css'
import { Link } from 'react-router-dom'
import DemoImg from '../../assets/img/demoImg.png'
import DemoModal from '../DemoModal/DemoModal';

const Request = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };
    
    const closeModal = () => {
        setIsModalOpen(false);
    };

  return (
    <div style={{margin: '7rem 0'}}>
        <div className='container request_container p-5 position-relative'>
            <div className="row">
                <div className="col-md-8 d-flex flex-column gap-5">
                    <h1 className='fw-bold' style={{fontSize: '3.5rem'}}>Grow Your Business With <span className='fst-underline'>IBS MULTIMEDIA</span></h1>
                    <Link className="text-white btn btn-primary w-40" onClick={openModal}>Request A Demo <FaChevronRight /></Link>
                </div>
                <div className="col-md-4">
                    <img src={DemoImg} className="position-absolute" alt="" />
                </div>
            </div>
        </div>
        {isModalOpen && <DemoModal modalIsOpen={isModalOpen} closeModal={closeModal} />}
    </div>
  )
}

export default Request
