import Graph1 from '../../assets/img/graph1.png'
import Graph2 from '../../assets/img/graph2.png'

const graphData = [
    {
        header: 'High Quality',
        title: 'Maintaining your own database of polygons is challenging',
        desc: 'You need data you can trust and rely on. We apply complex engineering and human verification to ensure accuracy of all POIs in our datasets.',
        link: 'Request a Demo',
        img: Graph1,
    },
    {
        header: 'Precise',
        title: 'Inaccurate data is costing you time and money',
        desc: 'Our accurate polygons allow you to reduce errors and more precisely attribute consumer visits to a specific place. Human verified building footprints are far superior to using a centroid radius, allowing you to determine who actually enters a POI versus who simply walks nearby.',
        link: 'Request a Demo',
        img: Graph2,
    },
]

export default graphData;