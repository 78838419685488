import React, { useState } from 'react';
import './Menu.modules.css';
import { Link } from 'react-router-dom';
import DemoModal from '../DemoModal/DemoModal';

const Menu = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };
    
    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <nav className="navbar d-flex flex-row justify-content-between align-items-center px-4 shadow" style={{padding: '0'}}>
            <div className="logo w-25">
                <Link to='/'>
                    <h3 className='m-0 p-0'>IBS MULTIMEDIA</h3>
                </Link>
            </div>
            {/* <div className="menu d-flex flex-row justify-content-between w-75"> */}
                {/* <div className="menu_list d-flex align-items-center">
                    <ul className='d-flex flex-row gap-4 p-0 m-0'>
                        <Link>
                            <li>About Us</li>
                        </Link>
                        <Link>
                            <li>Analytics</li>
                        </Link>
                        <Link>
                            <li>AI Revolution</li>
                        </Link>
                        <Link>
                            <li>Contact Us</li>
                        </Link>
                    </ul>
                </div> */}
                <div className="menu_CTA d-flex align-items-center">
                    <button className="btn btn-primary text-white" onClick={openModal}>
                        Schedule a Demo
                    </button>
                </div>
            {/* </div> */}
            {isModalOpen && <DemoModal modalIsOpen={isModalOpen} closeModal={closeModal} />}
        </nav>
    );
};

export default Menu;
