import React, { useState, useEffect } from 'react';
import Data from './bigData_data';

const Wheel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isRotating, setIsRotating] = useState(true);

  useEffect(() => {
    if (isRotating) {
      const interval = setInterval(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % Data.length);
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [isRotating, activeIndex]);

  const handleClick = (index) => {
    setIsRotating(false);
    setActiveIndex(index);
  };

  return (
    <div className='py-5 position-relative' style={{ background: 'rgba(0,0,0,0.1)' }}>
      <div className="box-container px-3  d-flex align-items-center justify-content-center">
        <div className="box-content w-50 text-center" style={{height: '14rem'}}>
          <h2>{Data[activeIndex].title}</h2>
          <p>{Data[activeIndex].description}</p>
        </div>
      </div>
      <div className="stripe-container px-3" style={{position: 'absolute', bottom: '0', background: '#0d6efd'}}>
        <div className="title-stripe d-flex flex-row justify-content-between gap-3">
          {Data.map((item, index) => (
            <div
              key={index}
              className={`btn btn-primary d-flex flex-row align-items-center stripe-item ${activeIndex === index ? 'active' : ''}`}
              onClick={() => handleClick(index)}
            >
              {item.title}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}


export default Wheel;
