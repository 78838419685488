import React from 'react'
import './Footer.modules.css'

const Footer = () => {
  return (
    <footer className='mt-5'>
        © IBS MULTIMEDIA 2023. All rights reserved.
    </footer>
  )
}

export default Footer
